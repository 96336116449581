<template>
  <div class="query-list">
    <el-breadcrumb class="query-breadcrumb" separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">{{dict[language].home}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{dict[language].queryResolve}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="query-key">{{dict[language].keywords}}：{{dataForm.title}}</div>
    <div class="query-list-warp">
      <div class="query-list-main">
        <section v-if="pageStatus === 'detail'">
          <span v-if="!curObj.content" class="query-list-empty">
            <div>{{dict[language].noData}}</div>
          </span>
          <div v-html="curObj.content" class="query-list-detail" v-else></div>
        </section>

        <section v-else-if="dataList.length > 0">
          <div class="query-resolve">
            <div v-for="data in dataList" :key="data.id">
              <el-row>
                <el-col :span="22">
                  <span @click="handlerClickList(data)" pointer>{{data.title}}</span>
                </el-col>
                <el-col :span="2">
                  <span class="sdf" right block>{{data.createDate.split(" ")[0]}}</span>
                </el-col>
                <!-- <el-col :span="4">
                  <span right block class="sdf">
                    <i class="el-icon-view"></i>
                    {{data.readNum}}
                  </span>
                </el-col> -->
              </el-row>
              <el-divider></el-divider>
            </div>
          </div>
          <div class="query-pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="dataForm.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="dataForm.limit"
              layout="total, prev, pager, next"
              :total="total"
              center
            >
            </el-pagination>
          </div>
        </section>

        <section class="query-list-empty" v-else>
          <div>{{dict[language].noData}}</div>
        </section>
      </div>

      <div class="query-list-right-nav" v-if="pageStatus === 'detail'">
        <div>
          <ul>
            <li :class="{active: data.id === curObj.id}" v-for="(data, index) in dataList" :key="data.id" @click="handlerClickList(data)">
              <el-tooltip :content="data.title" placement="top-start">
                <span>{{index+1}}、{{data.title}}</span>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <p class="content-list-more" v-if="total > dataList.length" @click="pageStatus = 'list'">
          {{dict[this.language].more}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixs from '@/mixs'
import dict from '@/assets/json/dictionaries.json'
import {getSwArticle,articleReadNum} from '@/http/request'
import {mapState} from 'vuex'

export default {
  name: "Query",
  inject: ['language'],
  mixins: [mixs],
  data() {
    return {
      pageStatus: 'list',
      currentPage: 1,
      dataList: [],
      curObj: {},
      dict,
      total: 0,
      dataForm: {
        page: 1,
        limit: 10,
        title: ""
      }
    };
  },
  computed:{
    ...mapState({
      searchKey: state => state.searchKey
    })
  },
  watch:{
    searchKey(newValue){
      this.$set(this.dataForm, 'title', newValue) 
      this.$set(this.dataForm, 'page', 1) 
      this.getSwArticle();
    }
  },
  created() {
    this.$set(this.dataForm, 'title', this.searchKey)  
    this.$set(this.dataForm, 'lanuage', this.language === 'zh' ? 0 : 1)
    this.getSwArticle();
  },

  methods: {
    // 请求文章列表
    getSwArticle() {
      getSwArticle(this.dataForm).then(({data}) => {
        if(data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total
          this.dataForm.page ++;
        }
      })
    },

    resetData() {
      this.$set(this.dataForm, 'limit', 10);
      this.$set(this.dataForm, 'page', 1);
      this.pageStatus = "list";
      this.total = 0;
    },

    // 点击文章列表
    handlerClickList(obj) {
      this.curObj = obj;
      this.pageStatus = "detail"
      this.articleReadNum(obj.id)
    },
    articleReadNum(id) {
      articleReadNum(id).then(({data}) => {
        if(data.code === 0) {
          console.log("阅读累加成功")
        }
      })
    },
    handleCurrentChange(n) {
      this.$set(this.dataForm, 'page', n);
      this.getSwArticle();
    },
  }
};
</script>

<style lang='less'>
.query-list {
  width: 933px;
  margin: 0 auto;
  .query-breadcrumb {
    margin: 25px 0 70px;
  }
  .query-key {
    margin-bottom: 55px;
    font-size: 31px;
    font-weight: 400;
    color: #2F6D8B;
    line-height: 20px;
  }
  .query-list-warp {
    display: flex;
    margin-bottom: 50px;
    .query-list-left-nav {
      width: 120px;
      & > span {
        display: block;
        padding-bottom: 30px;
        font-size: 15px;
        font-weight: bold;
        color: #595757;
      }
      div {
        height: 100%;
        // border-left: solid 1px #e6e6e6;
        ul {
          li {
            padding: 10px 15px;
            font-size: 13px;
            font-weight: 400;
            color: #595757;
            line-height: 43px;
            position: relative;
            border-left: solid 1px #e6e6e6;
            cursor: pointer;
            &.active {
              font-size: 15px;
              font-weight: 500;
              color: rgba(20, 153, 220, 1);
              &::before {
                width: 5px;
                height: 30px;
                content: "";
                position: absolute;
                left: 0;
                top: 16px;
                background: rgba(20, 153, 220, 1);
              }
            }
          }
        }
      }
    }
    .query-list-main {
      flex: 1;
      padding: 0 30px 30px;
      .query-pagination {
        margin: 50px 0;
      }
      .query-resolve {
        font-size: 14px;
        .sdf {
          font-size: 12px;
          color: rgba(114, 113, 113, 1);
        }
      }
      .query-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #999;
      }
    }
    .query-list-right-nav {
      width: 130px;
      box-sizing: border-box;
      ul {
        width: 100%;
        li {
          width: 100%;
          padding: 20px 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: 400;
          color: #595757;
          line-height: 1.5;
          position: relative;
          border-bottom: solid 1px #e6e6e6;
          cursor: pointer;
          &.active {
            font-weight: 500;
            color: rgba(20, 153, 220, 1);
            &::before {
              width: 100%;
              height: 5px;
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              background: rgba(20, 153, 220, 1);
            }
          }
          &:first-child {
            border-top: solid 1px #e6e6e6;
          }
        }
      }
      .query-list-more {
        padding: 20px 0 10px;
        text-align: right;
        font-size: 14px;
        color: rgba(20, 153, 220, 1);
        cursor: pointer;
      }
    }
    .el-divider--horizontal {
      margin: 10px 0 50px;
    }
  }
  .query-list-detail {
    margin-bottom: 50px;
  }
}
</style>